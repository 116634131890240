
.slider-container {
    min-height: 32rem;
    width: 100%;
}

.square-button {
    display: flex;
    align-items: center;
    height: 3rem;
    width: 3rem;
    color: #2580D5 !important;
    margin: 1em .5em;
    & .button-icon {
        filter: invert(43%) sepia(82%) saturate(489%) hue-rotate(168deg) brightness(90%) contrast(103%);
        height: 80%;
    }
    &:hover {
        & .button-icon {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(186deg) brightness(105%) contrast(105%);
        }
    }
}



.proyect-card {
    border-radius: 25px;
    
width: 400px;
max-width: 100%;
height: 22rem;
@media only screen and (max-width: 768px) {
    height: fit-content;
    width: 100%;
}
}

.card-screen-1 {
    background-color:rgba(64, 100, 120, .9);
    border-radius: 25px;
}

.card-screen-2 {
    background-color: rgba(18, 125, 190, .9);
    border-radius: 25px;
}

.card-screen-3 {
    background-color: rgba(131, 145, 166, .9);
    border-radius: 25px;
}

.content-category-cont {
    box-sizing: border-box;
}

.content-category {
    border: 1px solid white;
    border-radius: 50px;
    padding: .3rem .75rem;
    width: fit-content;
    height: fit-content;
    font-size: 12px;
}

.content-description-container {
    max-height: 14rem;
    overflow: hidden;
    text-align: justify;
  text-justify: inter-word;
    // white-space: nowrap;
}

.content-description-title {
    font-family: "Barlow-Regular";
    font-size: 22px;
}

.content-description-body {
    text-overflow: ellipsis !important;
    font-size: 14px;
    @media only screen and (max-width: 992px){
        font-size: 13px;
    }
    @media only screen and (max-width: 768px){
        font-size: 14px;

    }
}

.modal-title {
    font-size: 20px;
}

.modal-text {
  white-space: pre-wrap;
  text-align: justify;
  text-justify: inter-word;
}

.modal-logo-container {
    height: 4rem;
    // width: 15rem;
}

// .carrousel {
//     overflow-x: scroll;
// }