$barlow_fonts: (
    "Barlow-Black",
    "Barlow-BlackItalic",
    "Barlow-Bold",
    "Barlow-BoldItalic",
    "Barlow-ExtraBold",
    "Barlow-ExtraBoldItalic",
    "Barlow-ExtraLight",
    "Barlow-ExtraLightItalic",
    "Barlow-Italic",
    "Barlow-Light",
    "Barlow-LightItalic",
    "Barlow-Medium",
    "Barlow-MediumItalic",
    "Barlow-Regular",
    "Barlow-SemiBold",
    "Barlow-SemiBoldItalic",
    "Barlow-Thin",
    "Barlow-ThinItalic"
);
$ibm_fonts: (
    "IBMPlexSans-Bold",
    "IBMPlexSans-BoldItalic",
    "IBMPlexSans-ExtraLight",
    "IBMPlexSans-ExtraLightItalic",
    "IBMPlexSans-Italic",
    "IBMPlexSans-Light",
    "IBMPlexSans-LightItalic",
    "IBMPlexSans-Medium",
    "IBMPlexSans-MediumItalic",
    "IBMPlexSans-Regular",
    "IBMPlexSans-SemiBold",
    "IBMPlexSans-SemiBoldItalic",
    "IBMPlexSans-Thin",
    "IBMPlexSans-ThinItalic"
);
$inter_fonts: (
  "Inter-Black",
    "Inter-Bold",
    "Inter-ExtraBold",
    "Inter-ExtraLight",
    "Inter-Light",
    "Inter-Medium",
    "Inter-Regular",
    "Inter-SemiBold",
    "Inter-Thin",
);

@each $font in $barlow_fonts {
    @font-face {
      font-family: $font;
      src: url("./Barlow/"+$font+".ttf") format('truetype');
    }
  }

@each $font in $ibm_fonts {
    @font-face {
      font-family: $font;
      src: url("./IBM_Plex/"+$font+".ttf") format('truetype');
    }
  }

@each $font in $inter_fonts {
    @font-face {
      font-family: $font;
      src: url("./Inter/"+$font+".ttf") format('truetype');
    }
  }