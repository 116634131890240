.name-text {
    font-size: 20px;
    text-align: start;
    color: #363F50;
}

.position-text {
    font-size: 12px;
    text-align: start;
    color: #6C768A;
}

.description-text {
    font-size: 15px;
    text-align: start;
    color: #7A7D89;
}

.profile-card {
    border: 1px solid #DDE2E9;
    box-shadow: 0px 2px 6px 3px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    img {
        border-radius: 100%;
    }
}