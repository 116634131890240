.img-container {
    height: 48px;
    width: 48px;
}

.title-container {
    height: min-content;
}

.contact-image {
    border-radius: 25px;
    width: 100%;
    height: auto;
    @media (max-width: 992px) {
        min-width: 20rem;
        height: auto;
    }
}

.white-mask {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 25px;
    // clear: float;
    width: 100%;
    height: 100%;
    background-color: rgba(240, 237, 237, 0.5);
    z-index: 100;
}

.contact-img-container {
    position: relative;
    width: fit-content;
    min-width: 20rem;
    height: fit-content;
}

.address-data {
    text-align: start;
    #email {
        font-weight: 500;
    }
    p {
        color: #222938;
        line-height: 150%;
        font-size: 16px;
    }
    // span {
    //     color: #626979;
    //     text-align: justify;
    //     text-justify: inter-word;
    // }
}

.contact-form {
    color: #4E4E4E;
    max-width: 32rem;
    text-align: start;
}

.variable-width {
    width: 66%;
    @media (max-width: 578px) {
        width: 100%;
    }
    @media (max-width: 992px) {
        width: 80%;
    }

}

.justified-text {
    color: #626979 !important;
    text-align: justify;
    text-justify: inter-word;
}