
.content-container {
  min-height: 27rem;
}

.service-body {
    color: #636878;
    text-align: start;
    strong {
      color: #636879;
      font-size: 20px;
    }
}

ul {
  list-style-image: url('../../../assets/icons/check-solid.svg');
  color: #636879;
  text-align: start;
}